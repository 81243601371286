import "./App.css";
import Confetti from "./Confetti";

const shareMessage = "I just ran my first container using Docker";
const shareLink = "https://docker.com/";

const App = () => {
  return (
    <div className="App">
      <Confetti />
      <header className="App-header">
        <h1 style={{ marginBottom: "0px" }}>Congratulations!!!</h1>
        <p style={{ marginTop: "10px", marginBottom: "50px" }}>
          You visited tomeido's first container.
        </p>
        <p style={{ marginTop: "10px", marginBottom: "50px" }}>
          This page is a gateway to all my content.
        </p>
        <div>
          <a
            target="_blank"
            href={
              "https://blog.naver.com/tomeido"
            }
            class="fa-solid fa-house"
            rel="noopener noreferrer"
          >
            {" "}
          </a>
          <a
            target="_blank"
            href={
              "https://www.instagram.com/to_mei_do/"
            }
            class="fa-brands fa-instagram"
            rel="noopener noreferrer"
          >
            {" "}
          </a>
          <a
            target="_blank"
            href={
              "https://www.youtube.com/@tomeid"
            }
            class="fa-brands fa-youtube"
            rel="noopener noreferrer"
          >
            {" "}
          </a>
        </div>
        <div>
          <a
            target="_blank"
            href={
              "https://twitter.com/to_mei_do"
            }
            class="fa-brands fa-x-twitter"
            rel="noopener noreferrer"
          >
            {" "}
          </a>
          <a
            target="_blank"
            href={
              "https://www.linkedin.com/in/tomeido/"
            }
            class="fa-brands fa-linkedin"
            rel="noopener noreferrer"
          >
            {" "}
          </a>
          <a
            target="_blank"
            href={
              "https://github.com/tomeido"
            }
            class="fa-brands fa-github"
            rel="noopener noreferrer"
          >
            {" "}
          </a>
        </div>
      </header>
    </div>
  );
};

export default App;
